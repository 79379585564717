/* tslint:disable */
/* eslint-disable */
/**
 * Simple Blog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediaPost200Response,
} from '../models/index';
import {
    MediaPost200ResponseFromJSON,
    MediaPost200ResponseToJSON,
} from '../models/index';

export interface MediaPostRequest {
    file?: Blob;
}

/**
 * 
 */
export class MediaApi extends runtime.BaseAPI {

    /**
     * Upload a file
     */
    async mediaPostRaw(requestParameters: MediaPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaPost200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaPost200ResponseFromJSON(jsonValue));
    }

    /**
     * Upload a file
     */
    async mediaPost(requestParameters: MediaPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaPost200Response> {
        const response = await this.mediaPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
